import Dashboard from "src/icons/Dashboard";
import TimeSheet from "src/icons/TimeSheet";
import Files from "src/icons/Files";
import Employee from "src/icons/Employee";
import AddEmployee from "src/icons/AddEmployee";
import AddTask from "src/icons/AddTask";
import Tasks from "src/icons/Tasks";
import Company from "src/icons/Company";
import type { ReactNode } from "react";

export const sections: ISection[] = [
  {
    title: "OVERVIEW",
    items: [
      {
        title: "Dashboard",
        path: "/dashboard",
        icon: <Dashboard fontSize="small" />,
      },
    ],
  },
];

export interface ISectionItem {
  path?: string;
  icon?: any;
  title: string;
  info?: ReactNode;
  children?: ISectionItem[];
  rightData?: ReactNode;
}
export interface ISection {
  title: string;
  items: ISectionItem[];
}

export const superAdminSections: ISection[] = [
  {
    title: "OVERVIEW",
    items: [
      {
        title: "Companies",
        path: "/dashboard/companies",
        icon: <Dashboard fontSize="small" />,
      },
      {
        title: "Add Company",
        path: "/dashboard/companies/add",
        icon: <Company fontSize="small" />,
      },
    ],
  },
];

export const AdminSections: ISection[] = [
  {
    title: "OVERVIEW",
    items: [
      {
        title: "Dashboard",
        path: "/dashboard",
        icon: <Dashboard fontSize="small" />,
      },
      {
        title: "Time sheet",
        path: "/dashboard/time-sheet",
        icon: <TimeSheet fontSize="small" />,
      },
      {
        title: "Files",
        path: "/dashboard/files",
        icon: <Files fontSize="small" />,
      },
      {
        title: "Employee",
        path: "/dashboard/employee",
        icon: <Employee fontSize="small" />,
      },

      {
        title: "Add Employee",
        path: "/dashboard/employee/add",
        icon: <AddEmployee fontSize="small" />,
      },
      {
        title: "Roles",
        path: "/dashboard/role-management",
        icon: <AddEmployee fontSize="small" />,
      },
      {
        title: "Add Task",
        path: "/dashboard/task/add",
        icon: <AddTask fontSize="small" />,
      },
      {
        title: "My task",
        path: "/dashboard/tasks",
        icon: <Tasks fontSize="small" />,
      },
    ],
  },
];

export const EmployeeSections: ISection[] = [
  {
    title: "OVERVIEW",
    items: [
      {
        title: "Dashboard",
        path: "/dashboard",
        icon: <Dashboard fontSize="small" />,
      },
      {
        title: "Time sheet",
        path: "/dashboard/time-sheet",
        icon: <TimeSheet fontSize="small" />,
      },
      {
        title: "Files",
        path: "/dashboard/files",
        icon: <Files fontSize="small" />,
      },
      {
        title: "Add Task",
        path: "/dashboard/task/add",
        icon: <AddTask fontSize="small" />,
      },
      {
        title: "My task",
        path: "/dashboard/tasks",
        icon: <Tasks fontSize="small" />,
      },
    ],
  },
];
